<template>
  <div>
    <Banner
      :banner_img="resBanners"
      banner_en="NEWS AND TRENDS"
      banner_cn="新闻动态"
    >
    </Banner>
    <div class="contain">
      <div class="c-header">
        <div class="c-title">{{ infoObj.title }}</div>
        <div class="clear">
          <div class="c-time fl">
            发布时间：
            <span>{{ infoObj.time }}</span>
          </div>
          <div class="c-time fr" v-if="type == 'impoptant'">
            来源：
            <span>广州工业投资控股集团有限公司官网-集团要闻栏目</span>
          </div>
        </div>
      </div>
      <div class="c-main ql-snow ql-editor" v-html="infoObj.content"></div>
      <div class="c-footer clear">
        <div class="f-left">
          <div
          class="nlink"
            @click="
                splicingUrl({
                    base: '/news-details?id=' + infoObj.prev.id,
                    index: infoObj.prev.index,
                    type:   $route.query.type
                },infoObj.prev.externallinks)
            "
            v-if="infoObj.prev"
            >上一条：{{ infoObj.prev.title }}</div
          >
          <div
          class="nlink"
             @click="
                splicingUrl({
                    base: '/news-details?id=' + infoObj.next.id,
                    index: infoObj.next.index,
                    type:   $route.query.type
                },infoObj.next.externallinks)
            "
            v-if="infoObj.next"
            >下一条：{{ infoObj.next.title }}</div
          >
          <!-- <router-link to="#">上一条：xxxx</router-link>
          <router-link to="#">下一条：xxxx</router-link>-->
        </div>
        <div class="f-right">
          <router-link to="/news" class="arows">返回 &nbsp; >></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/common/Banner.vue";
export default {
  components: { Banner },
  data() {
    return {
      resBanners: [],
      infoObj: {},
      type: this.$route.query.type,
    };
  },
  mounted() {
    // console.log(this.$route.query);
    this.getinfo();
    this.getBanners();
  },
  watch: {
    // 监听路由发生改变
    $route: {
      handler(newVal) {
        if (newVal.query.id) this.getinfo();
      },
    },
  },
  methods: {
    // url拼接
    splicingUrl({ base, index, type },externallinks) {
        // console.log(externallinks)
        if(externallinks){
            window.open(externallinks)
        }else{
            this.$router.push({ path: base + "&index=" + index + "&type=" + type });
        }
        // return base + "&index=" + index + "&type=" + type;
    },
    // 获取banner
    async getBanners() {
      let res = await this.$request.getImageAdvertisingList({
        advertisingPosition: "2",
      });
      if (res.code === 200) {
        this.resBanners = [];
        this.resBanners = res.rows;
      }
    },
    returnPre() {
      this.$router.go(-1);
    },
    onConversion(date) {
      if (date.indexOf("T") === -1) {
        return this.onConversion2(date);
      } else {
        return this.onConversion1(date);
      }
    },
    // 时间转换
    onConversion2(date) {
      // console.log(1,date);
      var nDate = new Date(date.replace(new RegExp(/-/gm), "/"));
      // let nDate = new Date(date),
      var y = nDate.getFullYear(),
        m = ("0" + (nDate.getMonth() + 1)).slice(-2),
        d = ("0" + nDate.getDate()).slice(-2);
      // console.log(date)
      return y + "-" + m + "-" + d;
    },
    onConversion1(date) {
      // console.log(2,date);
      var arr = date.split("T");
      var d = arr[0];
      var darr = d.split("-");

      var dd =
        parseInt(darr[0]) +
        "-" +
        parseInt(darr[1]) +
        "-" +
        parseInt(darr[2]) +
        " ";
      return dd;
    },
    async getinfo() {
      let resQuery = this.$route.query;
      let res, resDatalist, infoColumn;
      if (resQuery.type === "company") {
        infoColumn = 0;
      } else {
        infoColumn = 4;
      }
      res = await this.$request.getNewInfoManageDetail({
        infoId: resQuery.id,
        infoColumn: infoColumn,
      });
      let resNext = "";
      if (res.rows[0].next) {
        resNext = res.rows[0].next;
      }

      let resPrev = "";
      if (res.rows[0].last) {
        resPrev = res.rows[0].last;
      }

      resDatalist = {
        id: res.rows[0].infoManage.infoId,
        title: res.rows[0].infoManage.infoName,
        content: res.rows[0].infoManage.infoContent,
        imgSrc: res.rows[0].infoManage.infoImage,
        index: resQuery.index,
        type: resQuery.type,
        time: this.onConversion(res.rows[0].infoManage.publishTime),
        next: resNext
          ? {
              id: resNext.infoId,
              title: resNext.infoName,
              index: resQuery.index - 0 + 1,
              ...resNext
            }
          : "",
        prev: resPrev
          ? {
              id: resPrev.infoId,
              title: resPrev.infoName,
              index: resQuery.index - 0 - 1,
              ...resPrev
            }
          : "",
      };
      //   } else {
      //     res = await this.$request.getNewdetail({
      //       infoId: resQuery.id,
      //     });
      //     let resNext = await this.$request.getNewList2({
      //       pageNo: resQuery.index - 0 + 1,
      //       pageSize: 1,
      //       channelName: "集团要闻",
      //     });
      //     let resPrev = "";
      //     if (resQuery.index - 0 - 1 >= 0) {
      //       resPrev = await this.$request.getNewList2({
      //         pageNo: resQuery.index - 0 - 1,
      //         pageSize: 1,
      //         channelName: "集团要闻",
      //       });
      //     }
      //     resDatalist = {
      //       id: res.data.jcContent.id,
      //       title: res.data.jcContent.title,
      //       content: res.data.jcContentTxt.attrTxt.replace(
      //         /src="\//g,
      //         'src="http://www.giihg.com/'
      //       ),
      //       imgSrc: res.data.resourcesSpaceData.resourceUrl,
      //       index: resQuery.index,
      //       type: resQuery.type,
      //       time: this.onConversion(res.data.jcContent.releaseTime),
      //       next:
      //         resQuery.index - 0 + 1 <= resNext.total
      //           ? {
      //               id: resNext.rows[0].jcContent.id,
      //               title: resNext.rows[0].jcContent.title,
      //               index: resQuery.index - 0 + 1,
      //             }
      //           : "",
      //       prev:
      //         resQuery.index - 1 > 0
      //           ? {
      //               id: resPrev.rows[0].jcContent.id,
      //               title: resPrev.rows[0].jcContent.title,
      //               index: resQuery.index - 0 - 1,
      //             }
      //           : "",
      //     };
      //   }
      this.infoObj = resDatalist;
      //   console.log(this.infoObj);
    },
  },
};
</script>
<style lang="scss">
.c-main {
  padding: 10px 40px 20px;

  img {
    max-width: 100% !important;
    display: block;
    margin: 40px auto;
  }

  p {
    color: #333333;
    text-indent: 32px;
    font-size: 18px;
    line-height: 30px;
    padding: 6px 0;
  }

  span {
    color: #333333;
  }

  b {
    display: inline-block;
    font-size: 18px;
    line-height: 30px;
    padding: 10px 0;
  }
}
</style>
<style lang="scss" scoped>
.contain {
  width: 62.5vw;
  min-height: 31.25vw;
  margin: 3.13vw auto;
  padding: 0 0 5.21vw 0;
  background-color: #f7f7f7;
  position: relative;
  /deep/table tr td {
    border: 1pt solid windowtext;
  }
  .c-header {
    padding: 2.08vw 0 1.04vw 0;
    margin: 0 2.08vw;
    text-align: left;
    border-bottom: 0.05vw solid #cccccc;

    .c-title {
      display: inline-block;
      font-size: 1.25vw;
      color: #333333;
      border-left: 0.52vw solid #f3ca00;
      padding: 0 0 0 0.52vw;
      font-weight: bold;
    }

    .c-time {
      font-size: 0.83vw;
      color: #666666;
      padding: 1.56vw 0 0 0;
      font-weight: bold;
    }
  }

  .c-footer {
    padding: 1.56vw 0;
    margin: 0 2.08vw;
    border-top: 0.05vw solid #cccccc;
    width: 58.33vw;
    position: absolute;
    bottom: 0;
    left: 0;

    .f-left {
      float: left;
      width: 36.46vw;

      .nlink {
        font-size: 0.83vw;
        color: #666666;
        padding: 0.52vw 0;
        width: 36.46vw;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        cursor: pointer;
        &:hover {
          color: #f3ca00;
        }
      }
    }

    .f-right {
      float: right;
      width: 6.77vw;

      .arows {
        width: 6.77vw;
        height: 1.56vw;
        line-height: 1.56vw;
        margin-top: 1.35vw;
        background-color: #f3ca00;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

@media (min-width: 1920px) {
  .contain {
    width: 1200px;
    min-height: 600px;
    margin: 60px auto;
    padding: 0 0 100px 0;
    background-color: #f7f7f7;
    position: relative;
    /deep/table tr td {
      border: 1pt solid windowtext;
    }
    .c-header {
      padding: 40px 0 20px 0;
      margin: 0 40px;
      text-align: left;
      border-bottom: 1px solid #cccccc;

      .c-title {
        display: inline-block;
        font-size: 24px;
        color: #333333;
        border-left: 10px solid #f3ca00;
        padding: 0 0 0 10px;
        font-weight: bold;
      }

      .c-time {
        font-size: 16px;
        color: #666666;
        padding: 30px 0 0 0;
        font-weight: bold;
      }
    }

    .c-footer {
      padding: 30px 0;
      margin: 0 40px;
      border-top: 1px solid #cccccc;
      width: 1120px;
      position: absolute;
      bottom: 0;
      left: 0;

      .f-left {
        float: left;
        width: 700px;

        a {
          font-size: 16px;
          color: #666666;
          padding: 10px 0;
          width: 700px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;

          &:hover {
            color: #f3ca00;
          }
        }
      }

      .f-right {
        float: right;
        width: 130px;

        .arows {
          width: 130px;
          height: 30px;
          line-height: 30px;
          margin-top: 26px;
          background-color: #f3ca00;
          color: #ffffff;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
